<template>
  <div class="main-content login">
    <div class="login-main">
      <div class="login-area">
        <div class="login-header">
          <img
            class="logo-img"
            src="@/assets/img/logo-01.svg"
            alt="工研院logo"
          />
          <h1 class="page-title">
            預見台灣新創獨角獸
          </h1>
        </div>
        <ValidationObserver ref="loginForm" class="validate-area">
          <!-- <div class="login-form" @submit.prevent>
            <div class="single-input-field">
              <ValidationProvider
                :rules="{
                  required: true
                }"
                v-slot="{ errors, failed }"
                name="account"
              >
                <div class="custom-input" :class="{ failed: failed }">
                  <input
                    type="text"
                    placeholder="請輸入帳號"
                    name="account"
                    v-model="account"
                  />
                </div>
                <span class="warn">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="single-input-field">
              <ValidationProvider
                :rules="{
                  required: true
                }"
                v-slot="{ errors, failed }"
                name="password"
              >
                <div class="custom-input" :class="{ failed: failed }">
                  <input
                    :type="fieldType"
                    placeholder="請輸入密碼"
                    name="password"
                    class="pwd"
                    v-model="password"
                  />

                  <i
                    class="fas fa-eye"
                    @click="changeToText"
                    v-show="fieldType == 'password'"
                  ></i>
                  <i
                    class="fas fa-eye-slash"
                    @click="changeToPassword"
                    v-show="fieldType == 'text'"
                  ></i>
                </div>
                <span class="warn">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <input
              type="button"
              value="登入"
              class="submit custom-btn"
              @click="submit"
            />
          </div> -->
        </ValidationObserver>
      </div>
      <LoginErrorModal />
    </div>
  </div>
</template>

<script>
import LoginErrorModal from '@/components/Modal/LoginErrorModal';
import { mapGetters, mapActions } from 'vuex';
import { localize } from 'vee-validate';
import zh_TW from 'vee-validate/dist/locale/zh_TW.json';

export default {
  name: 'Login',
  components: { LoginErrorModal },
  data() {
    return {
      fieldType: 'password',
      account: '',
      password: ''
    };
  },

  created() {
    localize({
      zh_TW: {
        messages: zh_TW.messages,
        names: {
          account: '帳號',
          password: '密碼'
        }
      }
    });
  },

  watch: {
    isLogin: {
      immediate: true,
      handler() {
        this.$router.push({ name: 'Unicorn' });
      }
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    changeToText() {
      this.fieldType = 'text';
    },

    //切換回密碼
    changeToPassword() {
      this.fieldType = 'password';
    },
    submit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.login({
            Email: this.account,
            LoginKey: this.password,
            callback: () => {
              this.$bvModal.show('login-error-modal');
            }
          });
        } else {
          return;
        }
      });
    }
  },
  computed: {
    ...mapGetters('user', ['isLogin'])
  }
};
</script>
