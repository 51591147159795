<template>
  <b-modal
    id="login-error-modal"
    modal-class="modal-style login-error-modal"
    ref="loginErrorModal"
  >
    <template>
      <h1>
        帳號或密碼錯誤，
        <br />
        請重新輸入
      </h1>
      <input
        type="submit"
        value="確定"
        class="submit"
        @click="$bvModal.hide('login-error-modal')"
      />
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>
